.venue-stats {
  .table-container {
    margin-top: 2rem;
    border: 1px solid $scale-light;
    border-bottom: none;
    border-radius: 0.5rem;

    &.is-loading {
      border: none;
    }

    .custom-table {
      padding: 0;
    }
  }
}
