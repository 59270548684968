@import './siteAssignments/styles';
@import './areaAssignments/styles';
@import './positionAssignments/styles';
@import './standAssignments/styles';

.page-template {
  overflow-y: auto !important;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 1rem;

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .venue {
      color: $primary;
      font-size: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
