*,
*::before,
*::after {
  box-sizing: border-box;
  overscroll-behavior-y: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  touch-action: none;
}

body > div {
  float: left;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

button {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: 0;
  border: 0;
  color: inherit;
}

button,
input[type='submit'],
input[type='button'] {
  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: 0;
  }
}

button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
