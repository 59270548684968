.alternative-table {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 0 2rem;

  @include mobile {
    max-height: 300px;
    padding: 0;
  }

  .layout-auto {
    table-layout: auto;
  }

  .layout-fixed {
    width: 100%;
    table-layout: fixed;

    @include mobile {
      table-layout: auto;
    }
  }

  table {
    width: 100%;
    thead {
      margin: 0;
      position: sticky;
      top: 0;
    }

    thead th,
    tbody td {
      padding: 0.75rem;
      box-sizing: border-box;
      border-bottom: 1px solid $scale-light;
      background: $scale-lightest;
      text-align: left;

      @include mobile {
        padding: 0.5rem;
      }
    }

    thead th {
      color: $primary;
      white-space: nowrap;
    }
  }

  .loading-indicator {
    padding: 1rem;
    color: $scale-darker;
  }
}
