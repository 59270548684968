$spacing: 1.25rem;

.allocations-modal {
  .modal-body {
    flex-direction: column;
  }

  .form {
    margin-top: $spacing;
    padding: 0 $spacing;

    .input-wrapper {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    padding: 0 $spacing;
    margin: $spacing 0;
  }
}
