.uniform-viewer-container {
  .viewer-img {
    position: relative;
    max-height: calc(var(--vh) * 100);
    overflow-y: scroll;
    margin-bottom: 2rem;

    .image {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }
}
