.page-events {
  padding: 0 !important;

  .react-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__tab-list {
      padding: var(--guideline) 0 0 var(--guideline);
    }

    &__tab-panel {
      flex: 1;
      overflow-y: auto;
    }
  }

  .calendar-container {
    padding: 0 1rem 1rem 1rem;

    & * {
      font-family: "Lato", sans-serif !important;
    }

    & > div {
      background-color: unset;
    }

    .calendar-day > div > ul {
      width: 100%;

      &::-webkit-scrollbar {
        width: 5px;
      }
        
      &::-webkit-scrollbar-track {
        background: #ddd;
      }
        
      &::-webkit-scrollbar-thumb {
        background: #666; 
      }
    }

    .calendar-item {
      cursor: pointer;

      h3 {
        font-weight: 500;
        font-size: 14px;
      }

      p {
        flex-shrink: 0;
      }
    }

    .calendar-navigation > div {
      display: flex;
      justify-content: space-between;
      margin: 0 0 0.5rem;

      & > div {
        line-height: 40px;
        padding: 0 2rem;
        font-size: 1.4rem;
      }

      & > button:first-child {
        padding: 0 0.75rem 0 0.25rem;
      }

      & > button:last-child {
        padding: 0 0.25rem 0 0.75rem;
      }
    }
  }
}
