.page-human-resources {
    padding: 0 !important;

    .react-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;

      &__tab-list {
        padding: var(--guideline) 0 0 var(--guideline);
      }
  
      &__tab-panel {
        flex: 1;
        overflow-y: auto;
      }
    }
  }
  