.position-assignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  .position {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9rem;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.25s linear;

    &:hover {
      background-color: $scale-lighter;
    }

    .name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      gap: 0.75rem;
      text-align: center;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5.25rem;
      height: 3rem;
      border-radius: 3px;
      background-color: $scale-light;
    }
  }

  button {
    transform: translateY(33.33%);
  }
}
