.multi-select {
  .multi__control {
    border: 1px solid $scale-mid;
    border-radius: 3px;
    min-height: 2.5rem;
    height: auto;
    box-shadow: none;

    &--is-focused {
      border: 1px solid $primary-lighter;

      &:hover {
        border: 1px solid $primary-lighter;
      }
    }
  }

  .multi__value-container {
    padding: 0.25rem 0.75rem;

    .multi__input-container {
      margin: 0;
      padding: 0;

      input {
        height: 100%;
      }
    }
  }

  .multi__indicator-separator {
    display: none;
  }

  .multi__multi-value {
    background-color: $primary-light;

    .multi__multi-value__label {
      color: $scale-lightest;
    }
  }

  .multi__multi-value__remove {
    svg {
      fill: $scale-lightest;
    }

    &:hover {
      background-color: $primary-lighter;
    }
  }
}

.error {
  .search__control {
    border: 1px solid $error;

    &:hover {
      border: 1px solid $error;
    }
  }
}
