.tag {
  display: inline-flex;
  align-items: center;
  min-height: 23px;
  line-height: 1;
  padding: 0 0.5rem;
  border-radius: $border-radius-mid;

  // + .tag {
  //   margin-left: 0.25rem;
  // }

  &.primary {
    background-color: $primary;
    color: $scale-lightest;
  }

  &.secondary {
    background-color: $primary-lightest;
    color: $primary;
  }

  &.light {
    background-color: $scale-light;
    color: $scale-darkest;
  }

  &.success {
    background-color: $success-lighter;
    color: $success;
  }

  &.error {
    background-color: $error-light-alternative;
    color: $error-light;
  }

  &.bronze {
    background-color: $bronze-lighter;
    color: $bronze;
    border: 1px solid $bronze;
  }

  &.silver {
    background-color: $silver-light;
    color: $silver;
    border: 1px solid $silver-lighter;
  }

  &.gold {
    background-color: $gold-lighter;
    color: $gold;
    border: 1px solid $gold;
  }

  &.platinum {
    background-color: $platinum-lighter;
    color: $scale-lightest;
    border: 1px solid $platinum;
  }

  &.clickable {
    cursor: pointer;
  }
}
