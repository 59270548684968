$footer-height: 3rem;
$check-td-width: 32px;
$table-background: $scale-lighter;
$table-font-color: $scale-darker;
$header-color: $scale-lighter;
$footer-color: $scale-lighter;
$table-selected: $primary;
$table-contrast: $scale-lighter;
$row-hover-background: $scale-light;

.table {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: $table-background;
  overflow: auto;

  * {
    font-family: 'Lato', sans-serif !important;
  }

  > .wrapper {
    float: left;
    width: 100%;
    height: calc(100% - #{$footer-height});
    overflow: auto;
  }

  &.alternative {
    > .wrapper {
      height: 100%;
    }
  }

  table {
    position: relative;
    float: left;
    min-width: 100%;
    width: auto;
    max-height: 100%;

    thead {
      color: $table-font-color;
    }

    th {
      position: sticky;
      z-index: 13 !important;
      top: 0;
      height: 53px;
      padding: 0 20px 14px;
      vertical-align: bottom;
      text-align: left;
      background-color: $header-color;

      > div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .options {
        display: inline;

        &:not(:empty) {
          margin-left: 0.5rem;
        }

        svg {
          float: right;
          color: $primary-light;
          cursor: pointer;
          transition: all 0.2s linear;

          &:not(.selected):hover {
            color: $scale-darker;
          }

          &.sort {
            height: 17px;
            transform-origin: center;

            &.desc {
              transform: rotate(180deg);
            }
          }

          &.filter {
            height: 11px;
          }

          &.selected {
            color: $primary;
          }
        }
      }
    }

    tbody {
      padding-top: 0.5rem;
      color: $table-font-color;

      tr {
        position: relative;

        td {
          background-color: $table-background;
        }

        &:hover td {
          background-color: $row-hover-background;
        }
      }
    }

    .column-switcher {
      display: inline-block;
      height: 1.25rem;
      transform-origin: center;
      transform: translateY(6px) rotate(90deg);
    }

    th.menu-buttons {
      background-color: $header-color;
    }

    td.menu-buttons {
      background-color: $table-background;
    }

    .menu-buttons {
      @include sticky($right: 0);
      width: 2.25rem;
      min-width: 2.25rem;
      z-index: 2;
      vertical-align: middle;

      .buttons-container {
        display: flex;
      }
    }

    th.menu-buttons {
      z-index: 8;
    }

    td {
      height: 44px;
      padding: 0 1.25rem;
      transition: background-color 0.15s linear;
    }

    .check {
      max-width: $check-td-width;
      min-width: $check-td-width;
      width: $check-td-width;
    }

    td.check {
      @include sticky($left: 0);
      z-index: 6;
      padding: 5px 0 0;
      text-align: right;

      .checkbox-wrapper {
        width: auto;
        display: inline-flex;
        margin: auto;
      }
    }
  }

  .loading {
    position: absolute;
    text-align: center;

    &:hover td {
      background-color: $table-background;
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $footer-height;
    padding: 0 1.25rem 0 0.75rem;
    background-color: $footer-color;
    color: $table-font-color;

    > div {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .hide-columns {
      margin-right: 1.5rem;
      padding: 0.5rem 1rem;

      .action-item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .info {
      display: inline-block;
      margin-right: 1.5rem;
    }

    .count-selected {
      margin-left: 1rem;
    }

    svg {
      height: 1rem;
      cursor: pointer;
    }
  }

  .select-column {
    background: transparent;
    height: 100%;
    padding: 0.25rem 0.75rem;
    border: 1px solid $primary-light;
    border-radius: 4px;
    transition: all 0.25s linear;

    &.marked {
      background: $primary-light;
      color: $scale-lightest;
    }
  }

  table th,
  table tbody tr td {
    &:empty {
      padding: 0;
    }
  }
}
