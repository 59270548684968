.summary {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid $scale-light;
  overflow-x: auto;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .element {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    border-right: 1px solid $scale-light;

    .values {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .ranking-text {
        color: $scale-lightest;
      }
    }

    &:last-child {
      border-right: none;
    }

    @include tablet {
      border-right: none;
    }

    @include mobile {
      justify-content: start;
    }

    svg {
      margin-right: 0.5rem;
      color: $primary;
    }

    .element-text {
      color: $scale-darker;
    }
  }
}
