.template-preview {
  .event-name {
    font-size: 20px;
    margin-bottom: 0.25rem;
  }

  .stadium {
    color: $primary;
  }

  .separator-line {
    border-top: 1px solid $scale-light;
    margin: 1rem 0;
  }

  .site {
    border: 1px solid $scale-light;
    border-radius: 6px;
    padding: 12px;
    margin-bottom: 1rem;

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      svg {
        color: $primary;
      }
    }

    .leaders {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;

      .text {
        color: $scale-darker;
        @include dot-separator;
      }
    }
  }

  .areas {
    margin-bottom: 1rem;

    .dropdown {
      border: 1px solid $scale-light;
      border-radius: 6px;
      padding: 12px;

      .arrow svg {
        transition: transform 0.25s linear;
      }

      .actionable {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.selected {
          .arrow svg {
            transform: rotate(180deg);
            transition: transform 0.25s linear;
          }
        }

        svg {
          color: $primary;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          div span {
            color: $scale-darker;
          }

          svg {
            color: $primary;
          }
        }
      }

      .dropdown-content {
        .area-leaders {
          display: flex;
          flex-wrap: wrap;
          gap: 0.75rem;
          margin-top: 0.5rem;
          margin-bottom: 1rem;

          .text {
            color: $scale-darker;
            @include dot-separator;
          }
        }

        .stand {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          border: 1px solid $scale-light;
          border-radius: 6px;
          padding: 12px;
          margin-bottom: 0.5rem;

          .description {
            color: $primary;
            margin-bottom: 0.25rem;
          }

          .stand-content {
            display: flex;
            flex-direction: column;

            .text {
              color: $scale-darker;

              &::before {
                content: '•';
                color: $primary;
                padding-right: 0.75rem;
                font-size: inherit;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}
