.ToolTipPortal {
  > div {
    font-family: 'Satoshi', sans-serif;
    font-size: 13px;
    font-weight: 500;
    transition: opacity 0.25s linear !important;
    box-shadow: none !important;
    padding: 0.25rem 0.75rem !important;
    transform: translateY(-6px);
    z-index: 201 !important;
    max-width: 250px;

    span {
      transition: none !important;
      top: -0.5rem !important;

      &:last-of-type {
        display: none;
      }
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
