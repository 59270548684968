.autocomplete {
  .search__control {
    border: 1px solid $scale-light;
    border-radius: 3px;
    height: 2.5rem;
    box-shadow: none;

    &--is-focused {
      border: 1px solid $primary-lighter;

      &:hover {
        border: 1px solid $primary-lighter;
      }
    }
  }

  .search__value-container {
    padding: 0 .75rem;

    .search__single-value {
      font-size: 14px;

      @include tablet {
        font-size: 12px;
      }
    }

    .search__input-container {
      margin: 0;
      padding: 0;
    }
  }

  .search__indicator-separator {
    display: none;
  }
}

.error {
  .search__control {
    border: 1px solid $error;

    &:hover {
      border: 1px solid $error;
    }
  }
}
