.react-tabs {

  &__tab-list {
    margin: 0 0 1rem;
  }

  &__tab {
    display: inline-block;
    border-bottom: none;
    position: relative;
    list-style: none;
    font-weight: 600;
    padding: .5rem;
    margin: 0 1rem;
    cursor: pointer;
    color: $scale-darker;

    &--selected {
      border-bottom: 2px solid;
      border-color: $primary-light;
      color: $primary-light;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
