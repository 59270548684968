.app {
  display: flex;
  float: left;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $scale-darkest;

  @include tablet {
    background-color: $scale-lightest;
  }

  > .transition-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > [class^="page-"]:not(.not-logged) {
      @include absolute(
        $top: calc($toolbar-height + var(--safe-area-inset-top, 0px)),
        $bottom: var(--safe-area-inset-bottom, 0px),
        $right: 0,
        $left: $sidemenu-width
      );
      overflow: hidden;
      padding: var(--guideline);

      @include tablet {
        left: 0;
      }
    }
  }

  [class^="page-"].contrast {
    margin: var(--guideline);
    border-radius: $border-radius-mid;
    background-color: $scale-lighter;

    @include tablet {
      margin: 0;
      border-radius: 0;
    }
  }

  @include mobile {
    .page-table {
      padding: 0 !important;

      .table {
        border: 0;
        border-radius: 0;
      }
    }
  }
}
