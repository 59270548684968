.reach-text-container {
  position: relative;

  .label {
    @include absolute();
    transform: translateY(50%);
    line-height: 1;
    // z-index: 1;
    white-space: nowrap;

    .text {
      position: relative;
      transform: translateY(-50%);
      color: $scale-dark;

      &.focused {
        color: $scale-darkest;
      }

      &::after {
        @include absolute($top: 10px, $left: 0);
        content: '';
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        border-color: white;
        z-index: -1;
      }
    }
  }

  .ql-editor strong,
  .ql-editor strong em,
  .ql-editor strong u {
    font-weight: bold;
  }

  .ql-toolbar {
    display: flex;
    justify-content: flex-end;
    border: 0;
    padding-right: 0;
    padding-top: 0;

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-container {
    border: 0;
    height: 220px;

    .ql-editor {
      border: 1px solid $scale-light;
      padding: 1.25rem;
      background-color: $scale-lightest;
      border-radius: 4px;

      &:focus {
        border: 1px solid $primary-light;
      }

      &::before {
        @include absolute($top: 1px, $right: 1.25rem, $left: 1px);
        content: '';
        height: 1.25rem;
        border-radius: 4px;
        background-color: $scale-lightest;
      }

      em {
        text-decoration: none;
      }
    }
  }

  .limit-info,
  .limit-error {
    text-align: right;
    display: block;
    padding: 0.25rem 0.25rem 0.5rem 0;
  }

  .limit-info {
    color: $scale-darker;
  }

  .limit-error {
    color: $error;
  }

  .ql-tooltip {
    left: -0.5rem !important;
    z-index: 1;
  }
}
