$channel-list-header-height: 70px;
$admin-message-color: #fff2b6;
$channel-preview-selected-color: rgba(87, 59, 255, 0.07);
$incoming-message-color: #eaeaea;
$gray-channel: #e5e4ee;

.my-chats-page {
  float: left;
  width: 100%;
  height: calc(100% - 0.25rem);
  overflow: auto;

  .sendbird-channel-settings {
    width: 100% !important;
    @include tablet {
      display: block;
      border-radius: 0px;
    }

    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: $scale-lightest;
      border-radius: 1rem;
    }

    .sendbird-channel-settings__header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 74px;

      @include tablet {
        background-color: $scale-darkest;
        transform: translateY(-3px);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        height: 64px;

        .sendbird-label--color-onbackground-1 {
          color: $scale-lightest;
          font-weight: 500;
        }

        .sendbird-channel-settings__header-icon {
          svg {
            path {
              fill: $scale-lightest !important;
            }
          }
        }
      }
    }

    .sendbird-channel-settings__scroll-area {
      .sendbird-channel-profile {
        min-height: 180px;

        .sendbird-channel-profile--inner {
          .sendbird-channel-profile__edit {
            span {
              display: none;
            }
          }

          .sendbird-channel-profile__title {
            max-width: 100%;
            font-size: 16px;
            margin-bottom: 1rem;
            overflow: hidden;
          }
        }
      }
      .sendbird-channel-settings__operator {
        .sendbird-channel-settings__freeze {
          max-width: 100%;

          svg {
            path {
              fill: $primary;
            }
          }
        }

        .sendbird-accordion__panel-header {
          .sendbird-badge {
            background-color: $primary;
            .sendbird-badge__text {
              span {
                color: $scale-lightest;
                font-weight: 400;
              }
            }
          }

          .sendbird-accordion__panel-icon-right {
            svg {
              path {
                fill: $scale-darkest;
              }
            }
          }

          .sendbird-channel-settings__accordion-icon {
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
      .sendbird-channel-settings__leave-channel {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .sendbird-conversation {
      .sendbird-conversation__scroll-container {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;

        .sendbird-conversation__messages-padding {
          padding: 1rem;

          .sendbird-msg-hoc {
            margin-bottom: 12px !important;
          }

          .sendbird-separator {
            padding-top: 15px;
            padding-bottom: 15px;

            .sendbird-label--caption-2 {
              font-weight: 400;
            }
          }
        }
      }

      .sendbird-message-content {
        .sendbird-message-content__middle {
          max-width: 280px;

          .sendbird-message-content__middle__body-container {
            width: auto;
          }

          .sendbird-message-content__middle__sender-name {
            color: $primary;
            margin-left: 0px;
            font-size: 14px;
            font-weight: 500;
          }

          .sendbird-text-message-item-body {
            &.incoming {
              background-color: $incoming-message-color;
              border-radius: 0px 6px 6px 6px !important;
            }

            &.outgoing {
              background-color: $primary;
              border-radius: 6px 0px 6px 6px !important;
            }
          }
        }
      }
    }

    .channel-header {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .channel-header-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid $gray-channel;
        padding-bottom: 1rem;
      }

      .back-btn {
        margin-bottom: 1rem;
      }

      @include tablet {
        background-color: $scale-darkest;
        transform: translateY(-1px);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        color: $scale-lightest;

        span {
          font-weight: 700;
        }
      }

      .channel-title {
        display: flex;
        align-items: center;
        text-align: center;

        @include tablet {
          display: block;
          color: $scale-lightest;
        }

        span {
          display: block;
        }

        .channel-name {
          margin-bottom: 5px;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include tablet {
            max-width: 200px;
          }
        }

        .channel-member-count {
          font-size: 14px;
          font-weight: 300;
        }
      }

      .channel-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        background-color: $gray-channel;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .channel-back {
        display: flex;
        align-items: center;

        button {
          padding: 0;
          gap: 0;
          background-color: $scale-darkest;
          color: $scale-lightest;
        }
      }
    }
  }

  .custom-channel-settings {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 0px !important;
    flex: 65%;
  }

  .sendbird-channel-list {
    border-radius: 8px;
    background-color: white;
    width: 100% !important;

    @include tablet {
      max-width: 100%;
      height: calc(100vh - $channel-list-header-height);
      max-height: 100%;
      border-radius: 0px;
    }

    .sendbird-channel-list__header {
      padding: 1rem 1.75rem 0rem 1.75rem;
      width: 100%;
      height: auto;

      .channel-header-container {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray-channel;
        padding-bottom: 1rem;
      }

      @include tablet {
        position: fixed;
        display: flex;
        align-items: center;
        height: 70px;
        padding: 1rem;
      }

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        background-color: $gray-channel;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .sendbird-channel-list__body {
      padding: 1rem;

      @include tablet {
        padding: 5rem 1rem 1rem 1rem;
      }

      .channel-preview {
        width: 100%;
        display: flex;
        border-radius: 8px;
        padding: 0.75rem;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-color: $channel-preview-selected-color;
        }

        @include tablet {
          padding: 1rem 0rem;
          border-bottom: 1px solid $gray-channel;
          border-radius: 0px;

          &:hover {
            background-color: transparent;
          }
        }

        .avatar {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin-right: 16px;
          background-color: $gray-channel;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        .channel-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          @include tablet {
            gap: 0.5rem;
            max-width: 80%;
          }

          .channel-info {
            display: flex;
            justify-content: space-between;
            gap: 1.5rem;

            span {
              font-size: 14px;
              max-width: 170px;

              @include tablet {
                max-width: 200px;
              }
            }

            .channel-name {
              max-width: 180px;
            }

            .date {
              font-size: 10px;
              color: #535372;
              white-space: nowrap;
            }
          }
        }

        .channel-last-message {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #535372;
          font-size: 14px;
          gap: 1.5rem;

          .hide-unread-message-count {
            display: none;
          }

          .show-unread-message-count {
            display: block;
          }

          @include tablet {
            width: 100%;
          }

          .channel-unread-message-count {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 40%;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $primary;
            color: white;
            font-size: 12px;
          }
        }
      }

      .channel-preview__selected {
        background-color: $channel-preview-selected-color;
      }
    }
  }
}

.sendbird-theme--light .sendbird-channel-settings {
  background-color: white;
  border-radius: 0.5rem;

  @include tablet {
    margin-top: 0;
  }
}

.sendbird-theme--light .sendbird-conversation {
  border: 0px !important;
  border-radius: 16px;

  @include tablet {
    border-radius: 0px;
    height: 100%;
  }
}

.sendbird-conversation__scroll-bottom-button {
  display: none;
}

.sendbird-conversation__footer {
  bottom: 0;
  background-color: $scale-lightest;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;

  @include tablet {
    border-radius: 0px;
  }

  .sendbird-message-input-wrapper {
    .sendbird-message-input-wrapper__message-input {
      .sendbird-message-input {
        button {
          span {
            color: $primary;
            svg {
              fill: $scale-lightest;
            }
          }
        }
      }
    }
  }
}

.sendbird-theme--light {
  .sendbird-icon-color--content-inverse [class*='fill'] {
    fill: $scale-dark;
  }
  .sendbird-icon-color--primary [class*='fill'] {
    fill: $primary;
  }
}

.sendbird-theme--light {
  .sendbird-message-content-reactions.primary {
    background-color: transparent;

    .sendbird-modal {
      .sendbird-modal__content {
        .sendbird-modal__body {
          .sendbird-more-members__popup-scroll {
            .sendbird-more-members__popup-scroll__inner {
              .sendbird-user-list-item {
                width: auto;
              }
            }
            // .sendbird-user-list-item {
            //   width: auto;
            // }
          }
        }
      }
    }
  }
}

.sendbird-fileviewer {
  height: calc(100vh - var(--safe-area-inset-top, 0px));
  top: var(--safe-area-inset-top, 0px);

  .sendbird-fileviewer__header {
    .sendbird-fileviewer__header__left {
      .sendbird-fileviewer__header__left__filename {
        display: none;
      }
    }
  }
}

.sendbird-thumbnail-message-item-body {
  max-width: 280px;
  min-width: 280px;
}

.sendbird-admin-message {
  .sendbird-admin-message__text {
    color: $scale-darker;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: $scale-darkest;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    background: $admin-message-color;
    border-radius: 6px;
    padding: 8px;
  }
}

.sendbird-theme--light .sendbird-message-content-reactions.mouse-hover.primary,
.sendbird-theme--light .sendbird-message-content-reactions:hover.primary {
  background-color: transparent;
}

.sendbird-theme--light .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background-color: $primary;
  border: 1px solid $primary;
}

.sendbird-theme--light .sendbird-input-toggle-button--checked {
  background-color: $primary;
  border: 1px solid $primary;
}

.sendbird-theme--light {
  .sendbird-button--primary {
    background-color: $primary;

    &:hover {
      background-color: $primary;
    }
  }

  .sendbird-file-message-item-body.outgoing {
    background-color: $primary;

    &:hover {
      background-color: $primary;
    }
  }
}

.sendbird-theme--light .sendbird-message-content-reactions {
  background-color: transparent !important;
}

.sendbird-user-list-item {
  width: auto;
}
