@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

// ====== Font face
// ======================================

$base-url: '../assets/fonts/';

@font-face {
  font-family: 'Satoshi';
  src: url($base-url + 'Satoshi-Black.eot');
  src: url($base-url + 'Satoshi-Black.eot?#iefix') format('embedded-opentype'),
    url($base-url + 'Satoshi-Black.woff2') format('woff2'), url($base-url + 'Satoshi-Black.woff') format('woff'),
    url($base-url + 'Satoshi-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url($base-url + 'Satoshi-Bold.eot');
  src: url($base-url + 'Satoshi-Bold.eot?#iefix') format('embedded-opentype'),
    url($base-url + 'Satoshi-Bold.woff2') format('woff2'), url($base-url + 'Satoshi-Bold.woff') format('woff'),
    url($base-url + 'Satoshi-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url($base-url + 'Satoshi-Medium.eot');
  src: url($base-url + 'Satoshi-Medium.eot?#iefix') format('embedded-opentype'),
    url($base-url + 'Satoshi-Medium.woff2') format('woff2'), url($base-url + 'Satoshi-Medium.woff') format('woff'),
    url($base-url + 'Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// ====== Heading tags and texts
// ======================================

h1 {
  font-size: 100px;
  font-weight: 600;

  @include tablet {
    font-size: 42px;
  }
}

h2 {
  font-size: 92px;
  font-weight: 600;

  @include tablet {
    font-size: 35px;
  }
}

h3 {
  font-size: 50px;
  font-weight: 500;

  @include tablet {
    font-size: 29px;
  }
}

h4 {
  font-size: 41px;
  font-weight: 500;

  @include tablet {
    font-size: 24px;
  }
}

h5 {
  font-size: 31px;
  font-weight: 500;

  @include tablet {
    font-size: 20px;
  }
}

h6 {
  font-size: 24px;
  font-weight: 500;

  @include tablet {
    font-size: 17px;
  }
}

.heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.heading-lg {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.body {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;

  &.light {
    color: $scale-dark;
  }

  @include tablet {
    font-size: 14px;
  }
}

.body-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  &.light {
    color: $scale-dark;
  }

  @include tablet {
    font-size: 12px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

strong,
.strong {
  font-weight: 600;
}

em {
  text-decoration: underline;
}

a,
.link {
  color: $primary;
  cursor: pointer;
}

a:hover,
.link {
  text-decoration: underline;
}
