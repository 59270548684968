.text-copy {
  position: relative;
  display: inline-block;
  background-color: inherit;

  > div {
    @include absolute($top: 0, $right: 0, $bottom: 0);
    background-color: inherit;
    padding: 2px 0 2px .5rem;
    opacity: 0;
    transition: opacity .25s linear;
  }

  .button.tiny {
    gap: 0;
    padding: 0 .25rem !important;
    height: 100%;
    width: auto;
    min-width: unset;

    svg {
      height: 14px;
    }
  }

  &:hover > div {
    opacity: 1;
  }
}
