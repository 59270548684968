.badge {
  display: inline;
  line-height: 1;
  padding: 2px .5rem .25rem;
  border-radius: 6px;
  color: $scale-lightest;

  &.success {
    background-color: $success-light;
  }

  &.danger {
    background-color: $error-light;
  }

  &.warning {
    background-color: $warning-light;
  }
}
