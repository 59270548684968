.training-input-file {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: 42px;
  padding: 0 2rem 0 0.25rem;
  border: 1px solid $scale-mid;
  border-radius: 0.25rem;
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    color: $primary;
    cursor: pointer;
  }

  > input {
    display: none;
  }

  .file-name {
    @include text-ellipsis;
    width: 100%;
  }
}

.file-name-container {
  margin-top: 0.5rem;

  .file-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }

  svg {
    cursor: pointer;
  }
}
