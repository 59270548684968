.button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  gap: 0.75rem;
  padding: 0 1.25rem;
  border-radius: $border-radius-sm;
  white-space: nowrap;
  transition: all 0.25s linear;

  .loading {
    margin-left: -0.25rem;
    animation: anim-button-rotation 2s infinite linear;
  }

  svg {
    height: 20px;

    @include tablet {
      height: 18px;
    }
  }

  &.primary,
  &.secondary {
    text-transform: uppercase;
  }

  &[disabled] {
    cursor: default !important;
  }

  &.primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: $scale-lightest;

    &:not([disabled]):hover {
      color: $primary-lighter;
    }

    &:focus,
    &:active {
      background-color: $primary;
      border-color: $primary;
    }

    &[disabled] {
      background-color: $primary-lighter;
      border-color: $primary-lighter;
    }
  }

  &.secondary {
    border: 1px solid $primary-light;
    color: $primary;

    &:not([disabled]):hover {
      border-color: $primary;
      color: $primary;
    }

    &.contrast {
      border-color: $scale-lightest;
      color: $scale-lightest;

      &:hover {
        border-color: $scale-light;
        color: $scale-light;
      }
    }

    &[disabled] {
      background-color: $scale-lightest;
      border-color: $scale-dark;
      color: $scale-dark;
    }

    &.contrast[disabled] {
      background-color: transparent;
    }
  }

  &.tertiary {
    color: $primary;

    &:focus {
      color: $primary-light;
    }

    &.contrast {
      color: $scale-lightest;

      &:focus {
        color: $scale-light;
      }
    }

    &[disabled] {
      background-color: transparent;
      border-color: $scale-dark;
      color: $scale-dark;
      text-decoration: none;
    }

    &.selected {
      background-color: $scale-light;
    }
  }

  &.success {
    color: $success-light;
  }

  &.error {
    color: $error-light;
  }

  &.alternative {
    color: $primary-light;

    &:focus {
      color: $primary;
    }

    &.contrast {
      color: $scale-lightest;

      &:focus {
        color: $scale-light;
      }
    }

    &[disabled] {
      background-color: transparent;
      border-color: $scale-dark;
      color: $scale-dark;
      text-decoration: none;
    }

    &.selected {
      background-color: $scale-light;
    }
  }

  &.rounded {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 0;
    border-radius: 50%;
    border: 1px solid $scale-lightest;
    color: $scale-lightest;
  }

  &.icon-only {
    width: 45px;
    min-width: 45px;
    padding-right: 0 !important;
    padding-left: 0 !important;

    @include tablet {
      width: 38px;
      min-width: 38px;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.small,
  &.tiny {
    min-height: auto;
    height: auto;
    line-height: 1;
  }

  &.small {
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 12px;
    display: inline-flex;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    svg {
      height: 16px;
    }
  }

  &.tiny {
    gap: 0.25rem;
    padding: 4px;
    font-size: 9.5px;

    &.icon-only {
      width: 1.75rem;
      min-width: 1.75rem;
    }

    svg {
      height: 14px;
    }
  }

  .badge {
    @include absolute($top: 0, $right: 0);
  }
}

@keyframes anim-button-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
