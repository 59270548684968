.alert {
  display: flex;
  align-items: center;
  gap: 8px;

  &.success {
    span,
    svg {
      color: $success;
    }
  }

  &.danger {
    span,
    svg {
      color: $error;
    }
  }

  &.warning {
    span,
    svg {
      color: $warning-light;
    }
  }
  
}
