.event-name-cell {
  width: auto;

  @include mobile {
    width: 110px;
  }
}

.event-date-cell {
  width: auto;

  @include mobile {
    width: 80px;
  }
}
