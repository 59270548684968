*,
*::before,
*::after {
  font-family: 'Satoshi', sans-serif;
  font-weight: 400;
}

hr {
  width: 100%;
  height: 1px;
  border: 0;
  margin: 1.25rem 0;
  background-color: $scale-mid;
}

// ====== Guides
// ======================================

:root {
  --guideline: 1.5rem;
  position: relative;
  padding-top: var(--safe-area-inset-top, 0rem);
  padding-bottom: var(--safe-area-inset-bottom, 0rem);

  @include tablet {
    --guideline: 1rem;
  }
}

// ====== Input
// ======================================

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin: .5rem 0;
  padding-bottom: .25rem;

  label {
    padding-bottom: .25rem;
  }

  input,
  select {
    height: 2.5rem;
  }

  textarea {
    resize: vertical;
    height: 140px;
  }

  input,
  select,
  textarea {
    padding: 0 .75rem;
    border: 1px solid $scale-mid;
    border-radius: $border-radius-sm;
    transition: border-color .25s ease-in-out;

    &:focus {
      border-color: $primary-lighter;
    }

    &[disabled] {
      background-color: $scale-lighter;
    }
  }

  select {
    background-color: $scale-lightest;

    option {
      background-color: $scale-lightest;

      &:hover {
        background-color: $primary;
      }
    }
  }

  .error-message {
    color: $error-light;
  }

  &.error {
    input,
    select,
    textarea {
      border-color: $error-light;
    }
  }
}

// ====== List
// ======================================

.list {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;

    > div {
      padding: .75rem 1rem;
    }

    > :first-child {
      width: 33%;
      font-weight: 600;
    }

    &:nth-child(odd) {
      background-color: $scale-light;
    }
  }
}

// ====== Ripple
// ======================================

.rippling {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.25rem;
    height: 1.25rem;
    background: rgba(#000, .1);
    display: block;
    border-radius: 9999px;
    opacity: 1;
    animation: .75s ease-out 1 forwards ripple-effect;
  }
}

@keyframes ripple-effect {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(10);
    opacity: .375;
  }
  100% {
    transform: translate(-50%, -50%) scale(35);
    opacity: 0;
  }
}
