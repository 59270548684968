.date-time-picker-wrapper {
  padding: 0.75rem 0;
  
  .react-datetime-picker {
    display: flex;
    flex-direction: column;
    padding-bottom: .25rem;
  
    label {
      padding-bottom: .25rem;
    }

    &__inputGroup__divider {
      padding: 1px 6px;
    }
    
  
    &__inputGroup {
      border: 0;
    } 
  
    &__wrapper {
      border: none;
    
      select,
      input {
        border: 1px solid $scale-mid;
        border-radius: $border-radius-sm;
        transition: border-color .25s ease-in-out;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
      }
    }
  
    .react-calendar {
      width: 100%;
      max-width: 100%;
      background-color: $scale-lightest;
      border: 1px solid $scale-mid;
    
      &__navigation {
        background-color: $primary-lightest;
        button {
          color: $primary;
          &:hover {
            background-color: $primary-lighter;
          }
          &:focus {
            background-color: $primary-lighter;
          }
        }
      }
    
      &__month-view__days {
        button {
          color: $scale-darkest;
          &:hover {
            background-color: $primary-light;
            color: $scale-lightest;
          }
        }
      }

      &__tile--now {
        background-color: transparent;
      }

      &__tile--active,
      &__tile--hasActive {
        background-color: $primary-light;
        color: $scale-lightest !important;
      }
  
      &__month-view__days__day--weekend {
        color: $error;
      }
    
      &__tile--hasActive &__tile--active,
      &__tile--hasActive:hover {
        background-color: $warning-light;
        color: $scale-darkest;
      }
    
      &__month-view__weekdays {
        text-transform: uppercase;
        color: $primary;
      }
    
      &__year-view &__tile,
      &__decade-view &__tile,
      &__century-view &__tile {
        &:hover {
          background-color: $primary-light;
        }
      }
    }
  }

  .error-message {
    color: $error-light;
  }

  &.error {
    input,
    select,
    textarea {
      border-color: $error-light;
    }
  }
}


