.percentage {
  max-width: fit-content;
  background-color: $primary-lightest;
  padding: 0rem 0.25rem;
  border-radius: 6px;

  span {
    color: $primary;
  }
}
