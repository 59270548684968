.applicant-summary {
  .summary-container {
    .summary-item {
      margin-bottom: 1rem;

      .title {
        color: $primary;
        font-weight: 500;
      }

      .profile-picture {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }

    .summary-divider {
      height: 1px;
      background-color: #ebebef;
      margin: 1rem 0;
    }
  }

  .apply-buttos {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    button {
      min-width: 130px;
    }
  }
}
