.event-details {

  .sales-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}

.different-held-on-and-sales-dates-warning,
.employee-capacity-warning {
  margin: 1rem 0;
}