.alcohol-viewer-container {
  margin: 0.5rem;

  .viewer-pdf {
    display: flex;
    flex-direction: column;
    max-height: calc(var(--vh) * 100);
    max-width: 100%;
    border: 1px $scale-mid solid;
    margin: 1.5rem 0;

    .pdf-loading {
      display: flex;
      justify-content: center;
    }

    canvas {
      display: flex;
      width: 100%;
      margin: 0 auto;
      object-fit: contain;
    }

    .photo-viewer-container {
      height: 100% !important;

      .photo {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .viewer-img {
    position: relative;
    max-height: calc(var(--vh) * 100);
    overflow-y: scroll;

    .image {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .form-wrapper {
    margin-top: 2.5rem;
  }
}
