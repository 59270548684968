// ====== Breakpoints
// ======================================

$breakpoint-tablet: 1024px;
$breakpoint-mobile: 600px;

// ====== Others
// ======================================

$toolbar-height: 4.25rem;
$sidemenu-width: 240px;
$max-screen-width: 1280px;

// ====== Border radius
// ======================================

$border-radius-lg: 8px;
$border-radius-mid: 6px;
$border-radius-sm: 3px;

// ====== Colors
// ======================================

$primary: #573bff;
$primary-light: #ac9fff;
$primary-lighter: #bcb1ff;
$primary-lightest: #ddd8ff;

$error: #ae2d2d;
$error-light: #da3333;
$error-lighter: #fff4f4;
$error-light-alternative: #f3d5d5;
$warning: #d19f3d;
$warning-light: #fdb834;
$warning-lighter: #fff9ea;
$success: #17926c;
$success-light: #46c69f;
$success-lighter: #ccf2d7;
$pending: #c9a14f;

$scale-lightest: #ffffff;
$scale-lighter: #f6f6f6;
$scale-light: #f0eff8;
$scale-mid: #d3d3d5;
$scale-dark: #a7a7ab;
$scale-darker: #64646b;
$scale-darkest: #22222c;

$bronze: #d0732c;
$bronze-lighter: #d377312e;
$silver: #758087;
$silver-lighter: #b7bcc0;
$silver-light: #b7bcc036;
$gold: #b58526;
$gold-lighter: #f9f29582;
$platinum: #605f5f;
$platinum-lighter: #605f5f96;

$backdrop: rgba($scale-darkest, 0.4);
