.toolbar {
  @include absolute;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: $toolbar-height;
  padding: 0 var(--guideline);
  background-color: $scale-lighter;

  @include tablet {
    background-color: $scale-darkest;
  }

  .menu-button svg {
    height: 21px;
  }

  .logo-link {
    display: flex;
    align-items: center;
    float: left;

    svg {
      height: 2.5rem;

      @include tablet {
        height: 1.75rem;
      }

      @include mobile {
        height: 1.5rem;
      }
    }
  }

  > :first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .75rem;

    button {
      display: none;

      @include tablet {
        display: flex;
      }
    }
  }

  > :last-child {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    @include tablet {
      gap: .75rem;
    }

    .extra-buttons-slot:empty {
      display: none;
    }
  }

  .middle-slot {
    flex: 1;
    text-align: center;
    padding: 0 1.5rem;

    @include tablet {
      padding: 0 1.25rem;
    }
  }

  .middle-slot,
  > :last-child {
    transition: opacity .25s linear;
  }

  &.menu-open {

    .middle-slot,
    > :last-child {
      opacity: 0;
      pointer-events: none;
    }
  }
}
