.stand-assignment {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.75rem;

  .stand {
    display: flex;
    gap: 1rem;
    align-items: center;
    border-radius: $border-radius-mid;
    border: 1px solid $scale-mid;
    padding: 1.5rem;
    height: 8rem;
    overflow-x: auto;
    overflow-y: hidden;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        max-width: fit-content;
      }
    }
  }
}

.options {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
