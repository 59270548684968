.area-assignment {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .assign {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow svg {
      transition: transform 0.25s linear;
    }

    &.selected {
      .arrow svg {
        transform: rotate(180deg);
      }
    }
  }
}

.add-stand-button {
  margin-top: 1rem;
}
