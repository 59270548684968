.alert-banner {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.75rem;
  padding: 1rem;

  &::before {
    content: '';
    margin: 0 1rem 0 .25rem;
    width: 3px;
    opacity: .5;
  }

  div {
    padding: .5rem 0;
  }

  &.warning {
    background-color: $warning-light;
    color: $scale-darkest;

    &::before {
      background-color: $scale-darker;
    }
  }

  &.error {
    background-color: $error-light;
    color: $scale-darkest;

    &::before {
      background-color: $scale-lighter;
    }
  }

  &.success {
    background-color: $success-light;
    color: $scale-darkest;

    &::before {
      background-color: $scale-lighter;
    }
  }
}
