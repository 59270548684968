$gap: 2.25rem;

.selector {
  width: calc(100% - #{$gap * 2});
  margin-left: $gap;

  .splide__arrow--prev {
    left: -#{$gap};
  }

  .splide__arrow--next {
    right: -#{$gap};
  }

  .splide__arrow {
    background-color: transparent;

    svg {
      fill: $scale-darkest;
    }
  }

  .btn {
    @include text-ellipsis;
    width: 100%;
    padding: .75rem 1.25rem;
    border: 1px solid $primary-lighter;
    background-color: $scale-lightest;
    border-radius: 4.5rem;
    line-height: 1;
    color: $scale-darker;
    white-space: nowrap;
    transition: .25s all linear;

    &.selected {
      border-color: $primary;
      background-color: $primary-lighter;
      color: $primary;
    }
  }
}
