.assign-leader-input {
  .leaders-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .leader {
      display: flex;
      padding: 1rem;
      border: 1px solid $scale-mid;
      border-radius: $border-radius-mid;

      .employees-container {
        flex: 70%;

        .title {
          display: flex;
          gap: 1rem;

          :last-child {
            color: $primary;
          }
        }

        label {
          font-size: 14px;
        }
      }

      .remove-button {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
