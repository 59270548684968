$transition: all .25s ease-in-out;
$menu-item-height: 2.75rem;

.side-menu {
  @include absolute($top: calc(#{$toolbar-height} + var(--safe-area-inset-top, 0rem)), $bottom: 0, $left: 0);
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: $sidemenu-width;
  padding: 1.25rem 1rem calc(var(--safe-area-inset-bottom, 0rem) + 1.25rem);
  background-color: $scale-darkest;
  overflow-x: hidden;
  overflow-y: auto;

  @include tablet {
    pointer-events: none;
    transform: translateX(-100%);
    transition: transform .5s ease-in-out;
  }

  &.open {
    pointer-events: auto;
    transform: translateX(0);
  }

  hr {
    background-color: rgba($scale-lightest, .05);
    margin: .5rem 0;

    &:last-of-type {
      display: none;
    }
  }

  > .group {
    margin-top: 1rem;
    margin-bottom: .5rem;
    color: $scale-lighter;

    &:first-child {
      margin-top: 0;
    }
  }

  .link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: $menu-item-height;
    min-height: $menu-item-height;
    margin: 1px -.5rem;
    padding: 0 .75rem;
    border-radius: $border-radius-mid;
    background-color: transparent;
    text-decoration: none;
    color: $primary-lightest;
    transition: $transition;

    svg {
      height: 20px;
      color: $primary-lightest;
      transition: $transition;
    }

    > div {
      padding-left: .75rem;
      transition: $transition;
      transition-duration: .5s;
    }

    &.selected {
      background-color: rgba($scale-lightest, .05);
      color: $primary-light;

      svg {
        color: $primary-light;
      }

      > div {
        padding-left: 1rem;
      }
    }

    &:not(.selected):hover {
      background-color: rgba($scale-lightest, .075);
    }
  }
}

.side-menu-backdrop {
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s linear;
}

@include tablet {
  .side-menu.open + .side-menu-backdrop {
    @include full-absolute;
    z-index: 1;
    opacity: 1;
    background-color: $backdrop;
    pointer-events: auto;
  }
}
