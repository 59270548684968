.edition-rates-and-tips-modal {

  .selector {
    margin-bottom: 2rem;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    > .input-wrapper {
      margin: 0;
      padding: 0;

      &:nth-child(n + 5) {
        grid-column: span 2;
      }
    }
  }
}
