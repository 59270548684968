.tags-input {
  .wrapper {
    display: flex;
    flex-direction: column;

    label {
      display: block;
      color: $scale-darkest;
      padding-bottom: 0.25rem;
    }

    .react-tagsinput {
      border-radius: 3px;
    }

    &.error {
      .react-tagsinput {
        border-color: $error-light;
      }
    }

    .react-tagsinput--focused {
      border-color: $primary;
      border: 0.5px solid $primary-lighter;
    }

    .react-tagsinput-tag {
      background-color: $primary-lightest;
      color: $primary;
      border: $primary-lightest;
    }

    .react-tagsinput {
      input {
        width: 120px;
        color: $scale-darkest;
      }
    }
  }

  .error-message {
    color: $error-light;
  }
}
