.search-in-toolbar {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: inline-block;
  margin: auto;

  input {
    width: 100%;
    height: 2.5rem;
    padding: 0 1.5rem 0 3rem;
    border: 1px solid $scale-mid;
    border-radius: $border-radius-mid;

    @include mobile {
      height: 2.25rem;
      padding: 0 .75rem;
      border-radius: $border-radius-sm;
    }
  }

  svg {
    @include absolute($top: 50%, $left: 1rem);
    transform: translateY(-50%);
    height: 1rem;
    color: $primary;

    @include mobile {
      display: none;
    }
  }
}
