.template-site-leader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .assign {
    display: flex;
    gap: 1rem;
  }
}
