$gap: .75rem;
$padding: 1.5rem;
$box-padding-left: 2.75rem;

.event-jobs {
  display: flex;
  flex-direction: column;
  gap: $gap;

  .site,
  .area-container {
    position: relative;
    padding-left: $box-padding-left;
    border-radius: $border-radius-mid;
    background-color: $scale-lightest;
    overflow: hidden;

    &::before {
      content: '';
      @include absolute($top: 0, $bottom: 0, $left: 0);
      width: 1rem;
    }
  }

  .site {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding-right: $padding;

    > :first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .leader {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $gap;
    }

    &::before {
      background-color: $scale-darkest;
    }
  }

  .area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 4rem;

    &::before {
      background-color: $scale-mid;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &:first-child {
        flex: 1;
        gap: 1rem;
      }
    }
  }

  .stand {
    position: relative;
    width: 100%;
    height: 8.25rem;
    border-radius: $border-radius-mid;
    border: 1px solid $scale-mid;

    &::before {
      background-color: $scale-mid;
    }

    .wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: $padding;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: .25rem;
      min-width: 8.75rem;
      margin-right: 2rem;
    }

    .stand-dropdown {
      @include absolute($top: .5rem, $right: .5rem);
    }
  }

  .areas {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }

  .arrow svg {
    transition: transform .25s linear;
  }

  .area-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: $padding;

    &::before {
      background-color: $scale-mid;
    }
  }

  .stands {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: $gap;
    padding-bottom: $padding;
    display: none;

    .add-position-btn {
      transform: translateY(33.33%);
    }
  }

  .area-container {

    &.selected {

      .stands {
        display: flex;
      }

      .arrow svg {
        transform: rotate(180deg);
      }
    }
  }

  .positions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gap;
  }

  .position {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9rem;
    gap: $gap;
    padding: $gap;
    border-radius: $border-radius-mid;
    cursor: pointer;
    transition: background-color .25s linear;

    &:hover {
      background-color: $scale-lighter;
    }

    .name {
      @include text-ellipsis;
      width: 100%;
      text-align: center;
    }

    .progress {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5.25rem;
      height: 3rem;
      border-radius: $border-radius-sm;
      background-color: $scale-light;
    }
  }
}
