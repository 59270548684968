.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  .responsive-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: black;
  }
}
