.react-timerange-picker {

  .react-timerange-picker__wrapper {
    flex-grow: 0;
    border: 0;
  }

  select,
  input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }

  .react-timerange-picker__range-divider {
    margin: 0 .5rem;
  }

  .react-timerange-picker__button {
    margin-left: .5rem;
  }
}
