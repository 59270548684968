.modal-base {
  @include full-fixed;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  .backdrop,
  .container {
    opacity: 0;
    transition: all 0.25s linear;
  }

  .backdrop {
    float: left;
    width: 100%;
    height: 100%;
    background-color: $backdrop;
  }

  .container {
    @include absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: calc(var(--vh) * 90);
    border-radius: 0.5rem;
    background-color: $scale-lightest;

    @include tablet {
      top: unset;
      bottom: 0;
      left: 0;
      transform: none;
      max-height: calc(var(--vh) * 100 - 5rem);
      border-radius: 0.5rem 0.5rem 0 0;
    }

    @include mobile {
      top: 0;
      max-height: unset;
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      border-radius: 0.5rem 0.5rem 0 0;
      min-height: 3.75rem;
      width: 100%;
      background: $scale-darkest;
      color: $scale-lightest;
      padding: 0 1.25rem 0 1.75rem;

      @include mobile {
        border-radius: 0;
      }

      svg {
        color: $scale-lightest;
        width: 25px;
        height: 25px;
      }
    }

    .modal-body {
      padding: 1.75rem;

      @include mobile {
        flex: 1;
      }

      > .input-wrapper:first-child {
        margin-top: 0;
      }

      > .input-wrapper:last-child {
        margin-bottom: 0;
      }
    }

    .scrollable {
      overflow-y: auto;
    }

    .modal-footer {
      display: flex;
      justify-content: space-around;
      padding: 1.5rem 1.75rem;
    }
  }

  .sm {
    width: 450px;

    @include tablet {
      width: 100%;
    }
  }

  .md {
    width: 572px;

    @include tablet {
      width: 100%;
    }
  }

  .lg {
    width: 950px;
  }

  .xl {
    width: 1050px;

    @include tablet {
      width: 100%;
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  &.init {
    .backdrop,
    .container {
      opacity: 1;
    }
  }

  &.closing {
    .backdrop,
    .container {
      opacity: 0;
    }
  }

  &.modal-table {
    .search-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .input-wrapper {
        flex: 1;
        padding-left: 1.25rem;
        color: $scale-darkest;
      }
    }

    .modal-body {
      display: flex;
      padding: 0;
      overflow: hidden;

      .table {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: unset;

        > .wrapper {
          min-height: 105px;
        }
      }
    }
  }
}
