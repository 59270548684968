.checkbox-wrapper {
  display: flex;
  align-items: center;

  .checkbox[type='checkbox'] {
    width: 1.25rem;
    height: 1rem;
    accent-color: $primary;
    margin: 0;
    cursor: pointer;
  }

  label {
    padding: 0 .25rem;
    line-height: 1;
    color: $scale-darkest;
  }
}
