.view-video {
  text-align: center;
  padding: 4px 10px 4px 10px;
  border-radius: 28px;
  border: 1px solid $primary;
  background-color: rgba(83, 60, 245, 0.08);
  color: $primary;
  font-size: 12px;
  max-width: fit-content;
  cursor: pointer;
  min-width: 100px;
}
