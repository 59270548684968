.page-appeal-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5.5rem;
  background: $scale-darkest;

  .box {
    width: 100%;
    max-width: 440px;
    min-width: 350px;
    padding: 1.75rem 1.5rem;
    background-color: $scale-lightest;
    overflow-x: auto;
  }

  .logo-container {
    text-align: center;
  }

  .logo {
    height: 3.75rem;
    margin-bottom: 1.25rem;

    path {
      fill: $scale-darkest;
    }
  }

  .submit-button {
    margin-top: 1.25rem;
  }
}
