.editable-money-cell {
  width: 100%;
  border: 1px solid $scale-mid;

  .input-wrapper {
    margin: 0px;
    padding-bottom: 0px;
  }

  input {
    width: 100%;
    border: none;
    background: transparent;
    height: 100%;
    padding: 0.25rem;
    outline: none;
    color: $scale-darker;

    &:focus {
      background-color: $scale-lightest;
    }
  }
}
