.inventory-modal {

  .items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    &.single {
      grid-template-columns: 1fr;
    }

    .item {
      @include aspect-ratio(16, 9);
      position: relative;
      border-radius: $border-radius-lg;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        @include absolute($right: 1rem, $bottom: 1rem);
        opacity: 0;
        transition: opacity .25s linear;
      }

      &:hover {
        button {
          opacity: 1;
        }
      }
    }
  }
}
