.alternative-text-area {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  textarea {
    width: 100%;
    border: 1px solid $scale-mid;
    height: 140px;
    border-radius: 4px;
    padding: 0.5rem;
  }

  .text-area-limit {
    text-align: right;
  }
}
