.date-picker {

  input {
    width: 100%;
  }
}

.react-datepicker {
  display: flex;
  flex-direction: row;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  top: 4px;
}
