.indicator {
    padding: 2px .5rem .25rem;
    border-radius: 6px;
    height: 1em;
    width: 1em;
  
    &.success {
      background-color: $success-light;
    }
  
    &.danger {
      background-color: $error-light;
    }
  
    &.warning {
      background-color: $warning-light;
    }
  }
  