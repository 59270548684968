.link-item {
  .button {
    margin: 0;
  }

  .button:first-child {
    border-radius: 3px 0 0 3px;
  }

  .button:last-child {
    border-radius: 0 3px 3px 0;
  }
}
