@import './tabs/details/_styles';
@import './tabs/jobs/_styles';
@import './tabs/statistics/_styles';

.page-event {
  .react-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__tab-panel {
      flex: 1;
      overflow-y: auto;
    }
  }
}
