.section-card {
  background-color: white;
  border-radius: $border-radius-mid;
  padding: 1.25rem 1.5rem;

  &.primary {
    width: 100%;
    height: 100%;
    border-left: 1rem solid $scale-darkest;
    border-radius: $border-radius-mid;
  }

  &.secondary {
    width: 100%;
    height: 100%;
    border-left: 1rem solid $scale-mid;
    border-radius: $border-radius-mid;
  }
}
