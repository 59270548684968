.incident-report-modal {

  .modal-body {

    > .item {
      margin-bottom: 1.25rem;
      padding: 1.25rem;
      border: 1px solid $scale-light;
      border-radius: $border-radius-lg;

      > span > span {
        font-weight: 600;
      }

      > div {
        margin-top: .25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
