.application-status {
  text-align: center;
  padding: 4px 6px;
  border-radius: 28px;
  font-size: 12px;
  max-width: fit-content;
  text-transform: capitalize;
  min-width: 100px;

  &.success {
    color: $success;
    border: 1px solid $success;
    background-color: rgba($success, 0.1);
  }

  &.pending {
    color: $pending;
    border: 1px solid $pending;
    background-color: rgba($pending, 0.1);
  }

  &.pending-review {
    color: $primary;
    border: 1px solid $primary;
    background-color: rgba($primary, 0.1);
  }

  &.rejected {
    color: $error-light;
    border: 1px solid $error-light;
    background-color: rgba($error-light, 0.1);
  }
}
