.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.not-logged {
    height: 100%;
    background-color: $scale-lightest;
  }
}
