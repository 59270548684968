.logo {
  fill: none;

  > g > path {
    fill: $scale-lightest;
  }

  &.contrast {
    > g > path {
      fill: $scale-darkest;
    }
  }
}
