.modal-view {

  .modal-body {
    background-color: $scale-lightest;
  }

  .item-wrapper {
    display: flex;
    width: 100%;
    padding: .75rem 0;
    background-color: inherit;

    > :first-child {
      width: 30%;
    }

    > div {
      flex: 1;
      background-color: inherit;
    }
  }
}
