@import './summary/styles';
@import './tabs/event/styles';

.employee-stats {
  .stats-container {
    margin: var(--guide) 0;
    background-color: $scale-lightest;
    border-radius: $border-radius-mid;

    .tabs-container {
      border: 1px solid $scale-light;
      border-radius: $border-radius-mid;

      @include mobile {
        border: none;
      }

      .react-tabs {
        &__tab {
          margin: 0;
          padding: 0;
          min-width: fit-content;
        }

        &__tab--selected {
          color: $primary;
          border-color: $primary;
        }

        &__tab-list {
          display: flex;
          padding: 1.5rem 2rem;
          gap: 1.75rem;
          overflow-x: auto;
          margin: 0;

          @include mobile {
            padding: 1rem;
          }
        }
      }
    }
  }
}
